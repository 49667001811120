<template>
  <div>
    <sidebar />
    <div class="main">
      <upbar title="Successful payment" class="pb-5" /> <br />
      <b-alert variant="success" disimissable show="30"
        >Thank you for your payment. The licenses are now assigned to your account.</b-alert
      >
    </div>

    <!-- CHECKOUT MODAL -->
    <b-modal title="Checkout" id="checkout" hide-footer>
      <checkout :order="orderSummary" :orderId="orderId"> </checkout>
    </b-modal>
  </div>
</template>

<script>
import sidebar from "../components/sidebar.vue";
import upbar from "../components/upbar.vue";
export default {
  name: "Buy",
  components: {
    sidebar,
    upbar,
  },
 
  data() {
    return {
      
    };
  },
  methods: { 
    makeToast(title, variant, textToShow) {
      this.$bvToast.toast(textToShow, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        solid: false,
      });
    },
  },
};
</script>
<style scoped>
.main {
  margin-top: 53px;
  margin-left: 370px;
}
.bar {
  margin-top: 53px;
  margin-right: 40px;
  height: 53px;
  border-radius: 2px;
  background: #f2f6ff;
}
 
.bar1 {
  margin-right: 40px;
  height: 82px;
  border-radius: 2px;
  border-bottom: 2px solid #dfe0eb;
}
.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #23438e;
  margin-left: 400px;
  margin-right: auto;
}
.tabTitle {
  margin-top: 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 21px;
  color: #2a4192;
}
.tabText {
  margin-top: 28px;
  font-size: 20px;
  line-height: 21px;
  color: #252733;
}
.buttons {
  margin-top: 18px;
}
.botton {
  width: 155px;
  height: 44px;
  margin-right: 25px;
}
.icon {
  margin-right: 25px;
  margin-top: 24px;
  width: 11%;
}
.touch {
  cursor: pointer;
}
.plus {
  margin-right: 40px;
  float: right;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  width: 268px;
  height: 50px;
  cursor: pointer;
  background: #2a4192;
  border-radius: 5px;
}
.plusIn {
  margin-top: 10px;
  margin-left: 40px;
}
.bicon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}
.iconPlus {
  margin-right: 20px;
}
</style>
